import { Database, Infinity, LayoutGrid, RefreshCcw, Rocket, Shield } from 'lucide-react';
import React from 'react';
import styles from './Features.module.css';

const FeatureCard = ({ title, description, Icon }) => (
  <div className={styles.featureCard}>
    <div className={styles.iconWrapper}>
      <Icon className={styles.icon} strokeWidth={1} />
    </div>
    <h3 className={styles.featureTitle}>{title}</h3>
    <p className={styles.featureDescription}>{description}</p>
  </div>
);

const Features = () => {
  const features = [
    {
      title: "One-Stop Platform",
      description: "Streamlines the complete LLM application lifecycle from development to operation, eliminating the need for multiple disconnected systems. This approach results in the most cost-effective total ownership (TCO).",
      Icon: Infinity
    },
    {
      title: "Superior Vector Search Engine",
      description: "Our cutting-edge vector database and search engine surpasses leading competitors, offering 10 times faster query responses, 5 times higher throughput, and a 3-fold reduction in costs.",
      Icon: Rocket
    },
    {
      title: "AI-Centric Data and Knowledge Management",
      description: "A groundbreaking system for handling data and knowledge, capable of efficiently processing diverse, large-scale structured and unstructured data. Say goodbye to concerns about outdated information.",
      Icon: Database
    },
    {
      title: "Advanced RAG Components",
      description: "Easily integrate cutting-edge, modular RAG and GraphRAG technologies without delving into complex coding. Our building blocks offer a plug-and-play solution for advanced implementations.",
      Icon: LayoutGrid
    },
    {
      title: "Rapid and Reliable Iteration",
      description: "Implement CI/CD-like evaluation processes to confidently adjust your AI application settings without fear of setbacks. Accelerate your development cycle and deploy to production in a matter of days instead of months.",
      Icon: RefreshCcw
    },
    {
      title: "Enterprise-Grade Security",
      description: "Benefit from sophisticated access control with granular, role-based, and privilege-based options. Seamlessly integrate with open-source and self-hosted LLMs, supporting both private cloud and on-site deployment strategies.",
      Icon: Shield
    }
  ];

  return (
    <section id="features" className={styles.features}>
      <div className="container mx-auto px-4 py-16">
        <h2 className={styles.sectionSubtitle}>Why Choosing Incepta</h2>
        <h1 className={styles.sectionTitle}>Everything you need to build your LLM product</h1>
        <p className={styles.sectionDescription}>All the data, integrations, and RAG tooling in one stack that just works.</p>
        <div className={styles.featureGrid}>
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;