import { motion } from 'framer-motion';
import React from 'react';
import styles from './Hero.module.css';

const Hero = () => {
  const openCalendly = () => {
    window.open('https://calendly.com/surendra-ganne/30min', '_blank', 'noopener,noreferrer');
  };

  const openStudio = () => {
    window.open('https://app.incepta.ai/', '_blank', 'noopener,noreferrer');
  };

  return (
    <section className={`${styles.hero} dark:bg-dark-bg`}>
      <div className="container mx-auto px-4 py-20 md:py-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={styles.content}
        >
          <h1 className={`${styles.title} dark:text-dark-text`}>
            AI Automation Framework
          </h1>
          <h2 className={`${styles.subtitle} dark:text-dark-accent`}>
            For Agents, Chatbots, and Workflows
          </h2>
          <p className={`${styles.description} dark:text-dark-muted`}>
            Create and deploy custom AI solutions without coding.
            Streamline operations and drive innovation.
          </p>
          <div className={styles.ctaContainer}>
            <button 
              className={`${styles.ctaPrimary} dark:bg-dark-accent dark:text-dark-bg dark:hover:bg-dark-muted`}
              onClick={openStudio}
            >
              Start Building
            </button>
            <button 
              className={`${styles.ctaSecondary} dark:bg-dark-card dark:text-dark-accent dark:border-dark-accent dark:hover:bg-dark-bg`}
              onClick={openCalendly}
            >
              Schedule Demo
            </button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;