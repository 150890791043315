import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState } from 'react';
import styles from './UseCases.module.css';

const UseCaseCard = ({ title, description }) => {
  const openCalendly = () => {
    window.open('https://calendly.com/surendra-ganne/30min', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={styles.useCaseCard}>
      <h3 className={styles.useCaseTitle}>{title}</h3>
      <p className={styles.useCaseDescription}>{description}</p>
      <button onClick={openCalendly} className={styles.learnMoreButton}>Learn More</button>
    </div>
  );
};

const UseCases = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const useCases = [
    {
      title: "YouTube Video Summary",
      description: "Incepta generates comprehensive video transcripts and summaries by simply inputting a video ID, enabling quick and efficient content analysis of YouTube videos."
    },
    {
      title: "AI-Powered Travel Consultant",
      description: "Our Travel Consultant feature creates personalized travel plans and provides detailed information about destinations, accommodations, and local conditions, enhancing the travel planning experience."
    },
    {
      title: "LinkedIn Insights Generator",
      description: "Leverage LinkedIn's public APIs to curate job listings and relevant posts based on specific keywords, keeping users informed about career opportunities and industry trends."
    },
    {
      title: "Contextual AI Chatbot",
      description: "Develop intelligent chatbots that access your knowledge base to generate contextually relevant responses, improving user engagement and information delivery."
    },
    {
      title: "RFP Analysis Assistant",
      description: "Streamline the RFP process with our tool that analyzes provided RFPs and generates comprehensive analysis reports, enhancing proposal evaluation efficiency."
    },
    {
      title: "CV Matcher",
      description: "Optimize recruitment by automatically identifying the most suitable resumes from your CV repository for any given job description, saving time and improving candidate selection."
    },
    {
      title: "Automated Answer Evaluator",
      description: "Enhance the grading process with our app that evaluates student answer scripts, providing detailed feedback and assessment, supporting educators in efficient and fair grading."
    },
    {
      title: "Investment Analysis Report Generator",
      description: "Empower investors with detailed, AI-generated Investment Analysis Reports for user-specified companies, facilitating informed decision-making in the financial sector."
    }
  ];

  const nextUseCase = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % useCases.length);
  };

  const prevUseCase = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + useCases.length) % useCases.length);
  };

  return (
    <section id="use-cases" className={styles.useCases}>
      <div className="container mx-auto px-4 py-16">
        <div className={styles.header}>
          <div>
            <h2 className={styles.sectionSubtitle}>Use Cases</h2>
            <h1 className={styles.sectionTitle}>How customers use Incepta</h1>
            <p className={styles.sectionDescription}>Check some of the use cases in vertical industries.</p>
          </div>
          <div className={styles.navigationButtons}>
            <button onClick={prevUseCase} className={styles.navButton}>
              <ChevronLeft size={24} />
            </button>
            <button onClick={nextUseCase} className={styles.navButton}>
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
        <div className={styles.useCaseContainer}>
          {useCases.slice(activeIndex, activeIndex + 3).map((useCase, index) => (
            <UseCaseCard key={index} {...useCase} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCases;