import { motion } from 'framer-motion';
import React from 'react';
import styles from './CTA.module.css';

const CTA = () => {
  const openCalendly = () => {
    window.open('https://calendly.com/surendra-ganne/30min', '_blank', 'noopener,noreferrer');
  };

  return (
    <section id="contact" className={`${styles.cta} dark:bg-gradient-to-r dark:from-primary-900 dark:to-primary-800`}>
      <div className="container mx-auto px-4 py-16">
        <motion.div 
          className={styles.content}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className={`${styles.title} dark:text-dark-text`}>Ready to Transform Your Business with AI?</h2>
          <p className={`${styles.description} dark:text-gray-300`}>
            Let's drive your business towards AI-powered growth and success.
          </p>
          <div className={styles.buttonGroup}>
            <button
              onClick={openCalendly}
              className={`${styles.ctaButton} dark:bg-dark-bg dark:text-primary-400 dark:hover:bg-primary-900`}
            >
              Let's Talk
            </button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default CTA;