import React from 'react';
import DarkModeToggle from '../DarkModeToggle';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <a href="/" className={styles.logo}>
          <img src="/logo-site.png" alt="Incepta Logo" className={styles.logoImage} />
        </a>
        <nav className={styles.nav}>
          <a href="#features" className={styles.navLink}>Features</a>
          <a href="#services" className={styles.navLink}>Services</a>
          <a href="#use-cases" className={styles.navLink}>Use Cases</a>
          <a href="#contact" className={styles.navLink}>Contact</a>
        </nav>
        <div className={styles.rightSection}>
          <DarkModeToggle className={styles.darkModeButton} />
          <a href="https://app.incepta.ai/" target="_blank" rel="noopener noreferrer" className={styles.studioButton}>
            Studio
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;