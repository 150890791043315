import React from 'react';
import CTA from './components/CTA/CTA';
import Features from './components/Features/Features';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services';
import UseCases from './components/UseCases/UseCases';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <Features />
        <Services />
        <UseCases />
        <CTA />
      </main>
      <Footer />
    </div>
  );
}

export default App;