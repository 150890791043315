import { Linkedin } from 'lucide-react';
import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`${styles.footer} dark:bg-dark-bg dark:text-dark-text`}>
      <div className="container mx-auto px-4 py-12">
        <div className={styles.gridContainer}>
          <div className={styles.logoSection}>
            <img src="/logo-site.png" alt="Incepta Logo" className={styles.logo} />
            <p className={`${styles.tagline} dark:text-gray-400`}>Building the Future of AI-Driven Innovation</p>
          </div>
          <div className={styles.linksSection}>
            <h3 className={`${styles.linkTitle} dark:text-primary-400`}>Quick Links</h3>
            <ul className={styles.linkList}>
              <li><a href="#features" className="dark:text-gray-300 dark:hover:text-white">Features</a></li>
              <li><a href="#services" className="dark:text-gray-300 dark:hover:text-white">Services</a></li>
              <li><a href="#use-cases" className="dark:text-gray-300 dark:hover:text-white">Use Cases</a></li>
              <li><a href="https://app.incepta.ai" target="_blank" rel="noopener noreferrer" className="dark:text-gray-300 dark:hover:text-white">Studio</a></li>
            </ul>
          </div>
          <div className={styles.contactSection}>
            <h3 className={`${styles.linkTitle} dark:text-primary-400`}>Contact Us</h3>
            <p className="dark:text-gray-300">Hyderabad, India</p>
            <a href="mailto:hello@incepta.ai" className={`${styles.email} dark:text-gray-300 dark:hover:text-white`}>hello@incepta.ai</a>
          </div>
          <div className={styles.socialSection}>
            <h3 className={`${styles.linkTitle} dark:text-primary-400`}>Follow Us</h3>
            <div className={styles.socialIcons}>
              <a href="https://www.linkedin.com/in/surenganne/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="dark:text-gray-300 dark:hover:text-white">
                <Linkedin className={styles.icon} />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.bottomBar}>
          <p className="dark:text-gray-400">&copy; {currentYear} Incepta. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;