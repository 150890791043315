import { Bot, Brain, Database, Key, Users, Workflow } from 'lucide-react';
import React from 'react';
import styles from './Services.module.css';

const ServiceCard = ({ title, description, Icon }) => (
  <div className={`${styles.serviceCard} dark:bg-dark-card`}>
    <div className={styles.iconWrapper}>
      <Icon className={`${styles.icon} dark:text-primary-400 dark:bg-primary-900`} strokeWidth={1} />
    </div>
    <div className={styles.content}>
      <h3 className={`${styles.serviceTitle} dark:text-primary-400`}>{title}</h3>
      <p className={`${styles.serviceDescription} dark:text-gray-300`}>{description}</p>
    </div>
  </div>
);

const Services = () => {
  const services = [
    {
      title: "AI-Powered Automation",
      description: "Streamline your workflows with intelligent automation.",
      Icon: Bot
    },
    {
      title: "Natural Language Processing",
      description: "Understand and generate human-like text with advanced NLP.",
      Icon: Brain
    },
    {
      title: "Predictive Analytics",
      description: "Make data-driven decisions with our predictive models.",
      Icon: Database
    },
    {
      title: "Computer Vision",
      description: "Process and analyze visual information from the world.",
      Icon: Key
    },
    {
      title: "Custom AI Development",
      description: "Tailored AI solutions to meet your specific business needs.",
      Icon: Users
    },
    {
      title: "AI Integration",
      description: "Seamlessly integrate AI capabilities into your existing systems.",
      Icon: Workflow
    }
  ];

  return (
    <section id="services" className={`${styles.services} dark:bg-dark-bg`}>
      <div className="container mx-auto px-4 py-16">
        <h2 className={`${styles.sectionTitle} dark:text-dark-text`}>AI Services</h2>
        <div className={styles.serviceGrid}>
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;